import React from "react";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import Layout from "../../layouts/LayoutIframe";
import PriceList from "../../components/iframe/wa-listing-builder-paywall/PriceList";
import "../../components/iframe/wa-listing-builder-paywall/index.scss";

const WaListingBuilderPaywall = () => {
  const { addProps, isRendered, paramsLink } =
    useQueryWaIframeParams("wa-listing-builder");

  return isRendered ? (
    <Layout withTagManager={false} className="PgIWLBP" turnOnEventView>
      <PriceList addProps={addProps} paramsLink={paramsLink} />
    </Layout>
  ) : null;
};

export default WaListingBuilderPaywall;
